import { theme } from "@/themes";
import { motion } from "framer-motion";
import React from "react";

interface FireworkAnimationProps {
  particleCount?: number;
  particleSize?: number;
  colors?: string[];
  duration?: number;
  persistOnAnimationEnd?: boolean;
}

const FireworkAnimation: React.FC<FireworkAnimationProps> = ({
  particleCount = 30,
  particleSize = 8,
  colors = [theme.extend.colors["primary-gold"]],
  duration = 1.5,
  persistOnAnimationEnd = false,
}) => {
  const particles = Array.from({ length: particleCount });

  return (
    <motion.div
      className="relative flex items-center justify-center"
      style={{
        width: `${particleSize * 25}px`,
        height: `${particleSize * 25}px`,
      }}
    >
      {particles.map((_, index) => {
        const angle = (360 / particleCount) * index;
        const color = colors[index % colors.length];

        return (
          <motion.div
            key={index}
            className="absolute rounded-full"
            style={{
              width: `${particleSize}px`,
              height: `${particleSize}px`,
              backgroundColor: color,
            }}
            initial={{ x: 0, y: 0, opacity: 0 }}
            animate={{
              x: Math.cos((angle * Math.PI) / 180) * 100,
              y: Math.sin((angle * Math.PI) / 180) * 100,
              opacity: 1,
              transitionEnd: {
                opacity: persistOnAnimationEnd ? 1 : 0,
              },
            }}
            transition={{
              duration,
              ease: "easeOut",
              repeat: 1,
              repeatDelay: 0.5,
            }}
          />
        );
      })}
    </motion.div>
  );
};

export default FireworkAnimation;

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  LegendItem,
} from "chart.js";
import React from "react";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumber } from "@/lib/utils";

export type DataType = "hotel" | "credit-card" | "flight";

export type PieDataType = {
  points: number;
  type: "hotel" | "credit-card" | "flight";
};

ChartJS.register(ArcElement, Tooltip, Legend);

const getPieChartColor = (type: DataType) => {
  switch (type) {
    case "hotel":
      return "#BD79DB";
    case "credit-card":
      return "#7ACADC";
    case "flight":
      return "#C2DB79";
  }
};

const PieChart: React.FC<{
  data: PieDataType[];
  animationDuration?: number;
}> = ({ data, animationDuration }) => {
  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    animation: { duration: animationDuration ?? 4000, easing: "easeOutExpo" },
    spacing: 18,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        fullSize: true,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          font: { size: 14 },
          color: "#ffffff",
          generateLabels: () => {
            const _data: LegendItem[] = data?.map((item) => {
              const points = formatNumber(item.points, 0);
              return {
                text:
                  item.type === "hotel"
                    ? `Hotel - ${points}`
                    : item.type === "credit-card"
                      ? `Credit Cards - ${points}`
                      : `Flights - ${points}`,
                fillStyle: getPieChartColor(item.type),
                pointStyle: "rectRounded",
                fontColor: "#ffffff",
                textAlign: "center",
              };
            });
            return _data;
          },
        },
      },
    },
  };
  const lables = data.map((item) =>
    item.type === "hotel"
      ? "Hotel"
      : item.type === "credit-card"
        ? "Credit Cards"
        : "Flights"
  );
  const values = data.map((item) => item.points);
  const color = data.map((item) => getPieChartColor(item.type));
  return (
    <Doughnut
      data={{
        labels: lables,
        datasets: [
          {
            data: values,
            backgroundColor: color,
            borderWidth: 0,
            spacing: 4,
            borderRadius: 10,
          },
        ],
      }}
      options={options}
    />
  );
};

export default PieChart;

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { theme } from "@/themes";
import { DialogProps } from "@radix-ui/react-dialog";
import React from "react";

type Props = DialogProps & {
  gondolaMappedLoading?: boolean;
  onGondolaMapped: () => void;
};

const TravelReviewModal: React.FC<Props> = ({
  gondolaMappedLoading,
  onGondolaMapped,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <DialogContent
        className="rounded-2xl p-0"
        style={{ background: theme.extend.backgroundColor.background }}
      >
        <div className="relative flex flex-col items-center justify-center overflow-hidden px-6 py-10">
          <img
            src="/assets/mapped/card-bg-lines.png"
            alt="lines"
            className="absolute -right-[15%] -top-[20%] z-0 scale-[1.2] object-cover"
          />
          <div className="text-linear-gradient-default-inverted text-center text-[32px] font-bold">
            Your travel year in review is here!
          </div>
          <Button
            variant="3d-gold"
            className="z-10 mt-6 h-10 whitespace-nowrap rounded-full text-sm font-semibold"
            onClick={onGondolaMapped}
            loadingClassName="text-primary-gold"
            loading={gondolaMappedLoading}
          >
            Gondola Mapped 2024
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TravelReviewModal;

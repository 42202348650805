import React, { useEffect } from "react";
import { TemplateComponentProps } from ".";
import Ellipse, { ellipseColor } from "../components/Ellipse";
import { useGondolaMapped } from "../gondolaMapperProvider";
import {
  cn,
  lgBreakPoint,
  screenInnerHeight,
  screenInnerWidth,
  smallHeightBreakPoint,
} from "@/lib/utils";
import GondolaMappedDecorations from "@/components/ui/svgComponents/GondolaMappedDecorations";
import { motion } from "motion/react";

const REVEAL_ANIMATION_DURATION = 1500;

const TopTraveller: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { setRenderExtraElements, gondolaMappedData } = useGondolaMapped();

  useEffect(() => {
    setRenderExtraElements?.(
      <div className="absolute -left-28 -top-10 z-0">
        <Ellipse color="blue" />
      </div>
    );
    return () => {
      setRenderExtraElements?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;

  return (
    <div
      className="relative flex flex-1 flex-col items-center justify-evenly overflow-hidden lg:px-16"
      style={{
        background: ellipseColor.blue.linearBackground,
      }}
    >
      <div
        className={cn(
          "flex w-full flex-col items-center",
          isSmallHeight ? "gap-4 pt-3" : "flex-1 gap-8 pt-5"
        )}
      >
        <div className="absolute bottom-[25%] left-4 -z-10">
          <GondolaMappedDecorations type="Star1" className="lg:scale-150" />
        </div>
        <div className="absolute left-4 top-12 -z-10">
          <GondolaMappedDecorations type="Star2" className="lg:scale-150" />
        </div>
        <div className="absolute right-8 top-20 -z-10">
          <GondolaMappedDecorations type="StarBrust" className="lg:scale-150" />
        </div>
        <div
          className={cn(
            "flex flex-col items-center justify-center gap-1",
            !isSmallHeight && "mt-5"
          )}
        >
          <span className="mx-4 text-center text-4xl font-semibold text-primary">
            For 2024
          </span>
          <span className="mx-4 text-center text-xl font-semibold text-primary">
            You are named
          </span>
        </div>
        <motion.div
          className={cn(
            "relative mx-3 flex h-full min-h-[225px] w-[350px] flex-col items-center justify-center gap-1 overflow-hidden rounded-lg border border-white/10 px-5 text-center shadow-2xl lg:min-h-[270px] lg:w-[420px]",
            isSmallHeight ? "py-3" : "mt-1 py-5"
          )}
          style={{
            background: "linear-gradient(180deg, #050E19 0%, #0D1F34 100%)",
          }}
          initial={{ filter: "blur(15px)" }}
          animate={{ filter: "blur(0)" }}
          transition={{ duration: REVEAL_ANIMATION_DURATION / 1000 }}
        >
          <img
            src="/assets/mapped/card-bg-lines.png"
            alt="lines"
            className="absolute -right-[35%] -top-[10%] scale-[1.2] object-cover"
          />
          <div className="text-center text-2xl font-bold text-primary lg:text-[32px]">
            {gondolaMappedData?.travelerType?.travelerType}
          </div>
          <div className="text-primary/60">
            You are a pioneering traveler who’s always first to explore new
            destinations.
          </div>
          <div
            className={cn(
              "h-[0px] w-full border border-white/10",
              !isSmallHeight && "my-4"
            )}
          ></div>
          <div className="text-center text-sm font-normal text-primary/80">
            Similar travelers loved
          </div>
          <div
            className={cn(
              "flex flex-col gap-2",
              isSmallHeight ? "w-[200px]" : "w-[250px]"
            )}
          >
            <img
              src={gondolaMappedData?.travelerType?.recommendedHotel?.heroImage}
              alt="hotel"
              className={cn(
                "w-full rounded-[10px] object-cover",
                isSmallHeight ? "h-[100px]" : "h-[170px]"
              )}
            />
            <div className="flex flex-col items-start gap-2">
              <div className="line-clamp-2 text-start text-base font-medium text-primary lg:line-clamp-none">
                {
                  gondolaMappedData?.travelerType?.recommendedHotel
                    ?.propertyName
                }
              </div>
              <div className="line-clamp-2 text-start text-[13px] font-normal leading-[16.90px] text-light-2">
                {gondolaMappedData?.travelerType?.recommendedHotel?.cityName},{" "}
                {gondolaMappedData?.travelerType?.recommendedHotel?.countryCode}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      {gondolaMappedData?.travelerType && (
        <motion.span
          initial={{ opacity: 0, filter: "blur(15px)" }}
          animate={{ opacity: 1, filter: "blur(0)" }}
          transition={{
            duration: REVEAL_ANIMATION_DURATION / 1000,
            delay: (REVEAL_ANIMATION_DURATION - 1000) / 1000,
          }}
          className="px-5 py-6 text-center text-base text-primary/80 lg:text-xl"
        >
          <span className="text-primary-gold">
            {gondolaMappedData.travelerType
              ?.percentageOfUserBaseHavingTheSameTravelerType || 0}
            %
          </span>{" "}
          of Gondola travelers are {gondolaMappedData.travelerType.travelerType}
          {"s"}, what a cool bunch!
        </motion.span>
      )}
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

export default TopTraveller;

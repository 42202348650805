"use client";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  cn,
  lgBreakPoint,
  screenInnerHeight,
  screenInnerWidth,
  smallHeightBreakPoint,
} from "@/lib/utils";
import { DialogProps } from "@radix-ui/react-dialog";
import React, { useEffect, useMemo, useRef } from "react";
import Template, { TemplateKeys, templates } from "./tempates";
import TemplateStatusTab from "./components/TemplateStatusTab";
import GondolaLogoWithText from "@/components/ui/svgComponents/GondolaLogoWithText";
import { ArrowLeft, ArrowRight, Loader2, Plus, X } from "lucide-react";
import { useGesture } from "@use-gesture/react";
import ShareContainer from "./components/ShareContainer";
import { useGondolaMapped } from "./gondolaMapperProvider";
import { toPng } from "html-to-image";
import { Button } from "@/components/ui/button";
import { addNewGoogleAccount } from "@/lib/googleUtils";
import { handleError } from "@/lib/apiUtils";

type Props = DialogProps;

const GondolaMapped: React.FC<Props> = ({ ...props }) => {
  const [currentTemplate, setCurrentTemplate] = React.useState<TemplateKeys>(
    TemplateKeys.WELCOME
  );
  const [shareLoading, setShareLoading] = React.useState(false);
  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;
  const mobileWidth = "min-w-full max-w-full";
  const mobileHeight = "min-h-full max-h-full";
  const desktopWidth = "lg:min-w-[650px] lg:max-w-[650px]";
  const desktopHeight = isSmallHeight
    ? "lg:min-h-full lg:max-h-full"
    : "lg:min-h-[800px] lg:max-h-[800px]";

  const timeoutRef = useRef<NodeJS.Timeout>();
  const isTapRef = useRef(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    renderExtraElements,
    gondolaMappedData,
    deleteGondolaMappedWelcomeAction,
    isWelcomeActionEnabled,
    hasSomeData,
  } = useGondolaMapped();

  const onNextTemplate = () => {
    const currentIdx = filteredTemplates.findIndex(
      (template) => template.key === currentTemplate
    );
    const newTempate = filteredTemplates[currentIdx + 1]?.key;
    if (!newTempate) return;
    setCurrentTemplate(newTempate);
  };

  const onPrevTemplate = () => {
    const currentIdx = filteredTemplates.findIndex(
      (template) => template.key === currentTemplate
    );
    const newTempate = filteredTemplates[currentIdx - 1]?.key;
    if (!newTempate) return;
    setCurrentTemplate(newTempate);
  };

  useEffect(() => {
    if (!props.open) {
      setCurrentTemplate(TemplateKeys.WELCOME);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open && isWelcomeActionEnabled) {
      deleteGondolaMappedWelcomeAction?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWelcomeActionEnabled, props.open]);

  const bind = useGesture({
    onPointerDown: ({ event }) => {
      event.preventDefault();
      isTapRef.current = true;
      timeoutRef.current = setTimeout(() => {
        isTapRef.current = false;
        // console.log("Hold detected!");
      }, 500);
    },
    onPointerUp: ({ event }) => {
      if (timeoutRef.current) {
        if (isTapRef.current) {
          // console.log("Tap detected!");
          const isPressedLeft = event.clientX < window.innerWidth / 2;
          const isPressedRight = event.clientX > window.innerWidth / 2;
          if (isPressedLeft) onPrevTemplate();
          if (isPressedRight) onNextTemplate();
        } else {
          // console.log("Released after hold!");
        }
        clearTimeout(timeoutRef.current);
      }
    },
    onPointerCancel: () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
  });

  const filteredTemplates = useMemo(() => {
    const showCountryVisited =
      (gondolaMappedData?.totalCountriesVisited?.items.length || 0) > 1;
    const showCityVisited =
      (gondolaMappedData?.totalCitiesVisited?.items.length || 0) > 1;
    const showFlightsTaken =
      (gondolaMappedData?.totalFlightsTaken?.lifetimeTotalNumberOfFlights ||
        0) > 1;
    const showHotelStays =
      (gondolaMappedData?.hotelStays?.currentYearTotalNumberOfHotels || 0) >= 2;
    const showYearEndPoints =
      (gondolaMappedData?.currentYearEndPoints?.creditCardPoints || 0) > 0 ||
      (gondolaMappedData?.currentYearEndPoints?.flightPoints || 0) > 0 ||
      (gondolaMappedData?.currentYearEndPoints?.hotelPoints || 0) > 0;
    const showYearEndRedeemedPoints =
      (gondolaMappedData?.pointsRedeemedCurrentYear?.creditCardPoints || 0) >
        0 ||
      (gondolaMappedData?.pointsRedeemedCurrentYear?.flightPoints || 0) > 0 ||
      (gondolaMappedData?.pointsRedeemedCurrentYear?.hotelPoints || 0) > 0;
    const showTopTraveller = !!gondolaMappedData?.travelerType?.travelerType;
    return templates.filter((t) => {
      switch (t.key) {
        case TemplateKeys.COUNTRY_VISITED:
          return showCountryVisited;
        case TemplateKeys.CITY_VISITED:
          return showCityVisited;
        case TemplateKeys.FLIGHTS_TAKEN:
          return showFlightsTaken;
        case TemplateKeys.HOTEL_STAYS:
          return showHotelStays;
        case TemplateKeys.POINTS_TOTAL:
          return showYearEndPoints;
        case TemplateKeys.POINTS_REDEEMED:
          return showYearEndRedeemedPoints;
        case TemplateKeys.TOP_TRAVELER:
          return showTopTraveller;
        default:
          return true;
      }
    });
  }, [gondolaMappedData]);

  const hasPrev = useMemo(() => {
    const currentIdx = filteredTemplates.findIndex(
      (template) => template.key === currentTemplate
    );
    return currentIdx > 0;
  }, [currentTemplate, filteredTemplates]);

  const hasNext = useMemo(() => {
    const currentIdx = filteredTemplates.findIndex(
      (template) => template.key === currentTemplate
    );
    return currentIdx < filteredTemplates.length - 1;
  }, [currentTemplate, filteredTemplates]);

  const onShare = async () => {
    if (containerRef.current === null) {
      return;
    }
    const orginalClassName = containerRef.current.className;
    try {
      setShareLoading(true);
      containerRef.current.className =
        containerRef.current.className + " rounded-none";
      containerRef.current.childNodes.forEach((node) => {
        if (node instanceof HTMLElement && node.id === "header-content") {
          node.className = node.className.replace(
            "justify-between",
            "justify-center"
          );
        }
      });
      const dataUrl = await toPng(containerRef.current, {
        includeQueryParams: false,
        cacheBust: true,
        filter: (node) => {
          if (
            node.id === "template-share-container" ||
            node.id === "template-status-tab" ||
            node.id === "template-close-button" ||
            node.id === "left-arrow" ||
            node.id === "right-arrow"
          ) {
            return false;
          }
          return true;
        },
      });
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      const file = new File([blob], "gondola-mapped-2024.png", {
        type: "image/png",
      });
      if (navigator.canShare({ files: [file] })) {
        await navigator.share({ files: [file] });
      } else {
        throw new Error("Your device doesn't support file sharing.");
      }
    } catch (error) {
      return;
    } finally {
      containerRef.current.childNodes.forEach((node) => {
        if (node instanceof HTMLElement && node.id === "header-content") {
          node.className = node.className.replace(
            "justify-center",
            "justify-between"
          );
        }
      });
      containerRef.current.className = orginalClassName;
      setShareLoading(false);
    }
  };

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  const showShare = filteredTemplates.find(
    (template) => template.key === currentTemplate
  )?.showShare;

  const onConnectNewGmail = async () => {
    try {
      addNewGoogleAccount();
    } catch (error) {
      handleError("Failed to connect Gmail", { reportError: true });
    }
  };

  return (
    <Dialog {...props}>
      <DialogContent
        className={
          hasSomeData
            ? cn(
                mobileWidth,
                mobileHeight,
                desktopWidth,
                desktopHeight,
                "user-select-none m-0 flex cursor-default flex-col items-center gap-0 overflow-hidden !rounded-2xl border-none p-0"
              )
            : "user-select-none m-0 flex cursor-default flex-col items-center gap-0 overflow-hidden !rounded-2xl border-none p-0 py-4"
        }
        style={hasSomeData ? { background: "black" } : {}}
        hideCloseButton={hasSomeData}
      >
        {hasSomeData ? (
          <>
            {shareLoading ? (
              <div className="absolute left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
                <Loader2 className="h-10 w-10 animate-spin text-white" />
              </div>
            ) : null}
            <div
              className="relative flex w-full flex-1 flex-col rounded-2xl bg-gondola-mapped-background pt-6"
              ref={containerRef}
            >
              {!isLessThanLg && (
                <>
                  {hasPrev && (
                    <div
                      id="left-arrow"
                      className="absolute left-2 top-1/2 z-20 -translate-y-1/2 transform"
                    >
                      <div
                        onClick={onPrevTemplate}
                        className="flex min-h-16 min-w-16 cursor-pointer items-center justify-center rounded-full border border-white/20"
                      >
                        <ArrowLeft className="h-7 w-7 cursor-pointer text-[#c6d3e6]" />
                      </div>
                    </div>
                  )}
                  {hasNext && (
                    <div
                      id="right-arrow"
                      className="absolute right-2 top-1/2 z-20 -translate-y-1/2 transform"
                    >
                      <div
                        onClick={onNextTemplate}
                        className="flex min-h-16 min-w-16 cursor-pointer items-center justify-center rounded-full border border-white/20"
                      >
                        <ArrowRight className="h-7 w-7 cursor-pointer text-[#c6d3e6]" />
                      </div>
                    </div>
                  )}
                </>
              )}
              <TemplateStatusTab
                allTemplates={filteredTemplates}
                currentTemplate={currentTemplate}
                isLast={
                  currentTemplate ===
                  filteredTemplates[filteredTemplates.length - 1].key
                }
                onTemplateChange={setCurrentTemplate}
                onNext={onNextTemplate}
                className="z-10 px-5"
              />
              <div
                id="header-content"
                className="z-10 flex w-full items-center justify-between px-5"
              >
                <GondolaLogoWithText className="mt-1 h-7 w-fit" />
                <X
                  id="template-close-button"
                  onClick={() => props.onOpenChange?.(false)}
                  className="cursor-pointer text-[#c6d3e6]"
                />
              </div>
              <div
                className="z-10 flex h-full w-full flex-1 flex-col rounded-b-2xl"
                {...(isLessThanLg ? bind() : {})}
              >
                <Template currentTemplate={currentTemplate} />
              </div>
              {renderExtraElements}
            </div>
            {isLessThanLg && showShare ? (
              <ShareContainer onShare={onShare} />
            ) : null}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 p-6">
            <div className="text-center text-lg font-medium text-primary">
              It looks like we couldn&apos;t find trips for your Travel Year in
              Review with this email account. If that trip info is associated
              with a different email you can sync your trips instantly here.
            </div>
            <Button
              className="z-30 text-sm text-neutral-950"
              onClick={onConnectNewGmail}
            >
              <Plus className="-ml-1 h-5 w-5 text-neutral-950" />
              Add Additional Accounts
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GondolaMapped;

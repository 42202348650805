import {
  BasicLatLng,
  getCenterAndZoomFromCoordinates,
  gondolaMapped,
  googleMapLoader,
} from "@/lib/mapUtils";
import { theme } from "@/themes";
import React, { useEffect, useRef, useState } from "react";
import { setCityAdvancedMarker, setFlightPinAdvancedMarker } from "../utils";
import {
  GondolaMappedCity,
  GondolaMappedCountry,
  GondolaMappedTotalFlightsTaken,
} from "@/generated/email_parser.openapi";
import { lgBreakPoint, screenInnerWidth } from "@/lib/utils";

const PER_COUNTRY_FILL_AINMATION = 400;
export const TOTAL_CITY_FILL_ANIMATION = 6000;
export const TOTAL_FLIGHTS_TAKEN_ANIMATION = 6000;

export const INITIAL_DELAY = 1500;

type Props = {
  countryVisited?: GondolaMappedCountry[];
  setCurrentlyLiteCountry?: React.Dispatch<
    React.SetStateAction<GondolaMappedCountry | undefined>
  >;
  setInitialDelayFinished?: React.Dispatch<React.SetStateAction<boolean>>;
  cityVisited?: GondolaMappedCity[];
  setCurrentlyLiteCity?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  flightsTaken?: GondolaMappedTotalFlightsTaken;
};

type FromTo = {
  from: BasicLatLng;
  to: BasicLatLng;
};

const MapComponent: React.FC<Props> = ({
  countryVisited,
  setCurrentlyLiteCountry,
  setInitialDelayFinished,
  cityVisited,
  setCurrentlyLiteCity,
  flightsTaken,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [map, setMap] = useState<google.maps.Map>();

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  useEffect(() => {
    (async () => {
      if (window.google?.maps.marker === undefined) {
        await googleMapLoader.importLibrary("marker");
      }
      if (window.google?.maps.geometry === undefined) {
        await googleMapLoader.importLibrary("geometry");
      }
      googleMapLoader.importLibrary("maps").then(() => {
        const map = new window.google.maps.Map(
          mapRef.current as HTMLDivElement,
          {
            zoom: isLessThanLg ? 0.45 : 0.7,
            center: { lat: 20, lng: 10 },
            mapId: gondolaMapped,
            gestureHandling: "greedy",
            restriction: {
              latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
            },
            isFractionalZoomEnabled: true,
            disableDefaultUI: true,
            draggable: false,
            // renderingType: google.maps.RenderingType.RASTER,
          }
        );
        setMap(map);
        setInitialLoading(false);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map && countryVisited?.length) {
      (async () => {
        const featureLayer = map.getFeatureLayer(
          google.maps.FeatureType.COUNTRY
        );
        const alreadyFilledCountries: GondolaMappedCountry[] =
          countryVisited.filter((c) => c.year !== 2024);
        const thisYearCountries: GondolaMappedCountry[] = countryVisited.filter(
          (c) => c.year === 2024
        );
        await new Promise((resolve) => setTimeout(resolve, INITIAL_DELAY));
        setInitialDelayFinished?.(true);
        for (let i = 0; i < thisYearCountries.length; i++) {
          const currentCountry = thisYearCountries[i];
          alreadyFilledCountries.push(currentCountry);
          setCurrentlyLiteCountry?.(currentCountry);
          featureLayer.style = (featureStyleFunctionOptions) => {
            const feature =
              featureStyleFunctionOptions.feature as google.maps.PlaceFeature;
            const country = alreadyFilledCountries.findLast(
              (item) => item.countryPlaceId === feature.placeId
            );
            const fillColor = country
              ? country.year === 2024
                ? theme.extend.colors["primary-gold"]
                : "#3f526d"
              : "transparent";
            return {
              fillColor,
              fillOpacity: 1,
            };
          };
          await new Promise((resolve) =>
            setTimeout(resolve, PER_COUNTRY_FILL_AINMATION)
          );
          if (i === thisYearCountries.length - 1) {
            setCurrentlyLiteCountry?.(undefined);
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, countryVisited]);

  useEffect(() => {
    if (map && cityVisited?.length) {
      (async () => {
        const { center, zoom } = getCenterAndZoomFromCoordinates(
          cityVisited.map((city) => ({
            lat: city.coordinates.latitude || 0,
            lng: city.coordinates.longitude || 0,
          })),
          { width: isLessThanLg ? 360 : 430, height: isLessThanLg ? 235 : 280 }
        );
        map.setCenter(center);
        map.setZoom(zoom);

        const cityCoordinates: {
          cityName: string;
          year: number;
          coordinates: google.maps.LatLng;
          color: "gold" | "white";
        }[] = cityVisited
          .filter(
            (city) => city.coordinates?.latitude && city.coordinates?.longitude
          )
          .map((city) => {
            return {
              cityName: city.cityName,
              year: city.year,
              coordinates: new google.maps.LatLng(
                city.coordinates?.latitude as number,
                city.coordinates?.longitude as number
              ),
              color: city.year === 2024 ? "gold" : "white",
            };
          });
        const pastYearCities = cityCoordinates.filter(
          (city) => city.year !== 2024
        );
        const thisYearCities = cityCoordinates.filter(
          (city) => city.year === 2024
        );
        pastYearCities.forEach((city) => {
          setCityAdvancedMarker(city.color, {
            map,
            position: city.coordinates,
            zIndex: 1,
          });
        });
        const perCityFillAnimation =
          TOTAL_CITY_FILL_ANIMATION / thisYearCities.length;
        await new Promise((resolve) => setTimeout(resolve, INITIAL_DELAY));
        setInitialDelayFinished?.(true);
        for (let i = 0; i < thisYearCities.length; i++) {
          const city = thisYearCities[i];
          setCurrentlyLiteCity?.(city.cityName);
          setCityAdvancedMarker(city.color, {
            map,
            position: city.coordinates,
            zIndex: 2,
          });
          await new Promise((resolve) =>
            setTimeout(resolve, perCityFillAnimation)
          );
          if (i === thisYearCities.length - 1) {
            setCurrentlyLiteCity?.(undefined);
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, cityVisited]);

  useEffect(() => {
    if (map && flightsTaken?.currentYearFlights?.length) {
      const fromToCoordinates: FromTo[] = flightsTaken?.currentYearFlights
        .filter(
          (flight) => flight.departureCoordinates && flight.arrivalCoordinates
        )
        .map((flight) => ({
          from: {
            lat: flight.departureCoordinates?.latitude as number,
            lng: flight.departureCoordinates?.longitude as number,
          },
          to: {
            lat: flight.arrivalCoordinates?.latitude as number,
            lng: flight.arrivalCoordinates?.longitude as number,
          },
        }));

      const { center, zoom } = getCenterAndZoomFromCoordinates(
        flightsTaken?.currentYearFlights.map((flight) => ({
          lat: flight.arrivalCoordinates?.latitude || 0,
          lng: flight.arrivalCoordinates?.longitude || 0,
        })),
        { width: isLessThanLg ? 360 : 430, height: isLessThanLg ? 235 : 280 }
      );
      map.setCenter(center);
      map.setZoom(zoom);
      const perPathDuration =
        (TOTAL_FLIGHTS_TAKEN_ANIMATION - 1) / fromToCoordinates.length;

      (async () => {
        await new Promise((resolve) => setTimeout(resolve, INITIAL_DELAY));
        for (let i = 0; i < fromToCoordinates.length; i++) {
          setFlightPath(fromToCoordinates[i], perPathDuration, map);
          await new Promise((resolve) => setTimeout(resolve, perPathDuration));
        }
      })();
    }
  }, [map, flightsTaken, isLessThanLg]);

  const setFlightPath = (
    { from, to }: FromTo,
    perPathDuration: number,
    map: google.maps.Map
  ) => {
    return new Promise<void>((resolve) => {
      const departureCoordinates = new google.maps.LatLng(
        from.lat || 0,
        from.lng || 0
      );
      const arrivalCoordinates = new google.maps.LatLng(
        to.lat || 0,
        to.lng || 0
      );
      const steps = 50;
      const polylinePath = new google.maps.MVCArray();
      const polyline = new google.maps.Polyline({
        path: polylinePath,
        geodesic: true,
        strokeColor: theme.extend.colors["primary-gold"],
        strokeOpacity: 1.0,
        strokeWeight: 1.5,
      });
      polyline.setMap(map);
      let currentStep = 0;
      setFlightPinAdvancedMarker({
        map,
        position: departureCoordinates,
      });
      const animatePolyline = () => {
        if (currentStep < steps) {
          const startPoint = google.maps.geometry.spherical.interpolate(
            departureCoordinates,
            arrivalCoordinates,
            currentStep / steps
          );
          polylinePath.push(startPoint);
          currentStep++;
          const timeOutDelay = perPathDuration / steps;
          setTimeout(animatePolyline, timeOutDelay);
          if (currentStep === steps) {
            setFlightPinAdvancedMarker({
              map,
              position: arrivalCoordinates,
            });
            resolve();
          }
        }
      };
      animatePolyline();
    });
  };

  return (
    <div className="relative mx-3 h-[225px] w-[350px] lg:h-[270px] lg:w-[420px]">
      {initialLoading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="h-full w-full rounded-lg bg-[#020C1B]" />
        </div>
      )}
      <div ref={mapRef} className="h-full w-full rounded-lg" />
    </div>
  );
};

export default MapComponent;

import React, { useEffect, useMemo } from "react";
import { TemplateComponentProps } from ".";
import Ellipse, { ellipseColor } from "../components/Ellipse";
import { useGondolaMapped } from "../gondolaMapperProvider";
import {
  cn,
  formatNumber,
  lgBreakPoint,
  screenInnerHeight,
  screenInnerWidth,
  smallHeightBreakPoint,
} from "@/lib/utils";
import GondolaMappedDecorations from "@/components/ui/svgComponents/GondolaMappedDecorations";
import PieChart, { PieDataType } from "../components/PieChart";
import CountUp from "react-countup";
import { motion } from "motion/react";

const REVEAL_ANIMATION_DURATION = 1500;

type DataType = "hotel" | "credit-card" | "flight";

const PointsRedeemed: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { setRenderExtraElements, gondolaMappedData } = useGondolaMapped();

  const pointsRedeemedCurrentYear =
    gondolaMappedData?.pointsRedeemedCurrentYear;

  const totalPoints =
    (pointsRedeemedCurrentYear?.creditCardPoints || 0) +
    (pointsRedeemedCurrentYear?.hotelPoints || 0) +
    (pointsRedeemedCurrentYear?.flightPoints || 0);

  useEffect(() => {
    setRenderExtraElements?.(
      <div className="absolute -left-28 -top-10 z-0">
        <Ellipse color="white" />
      </div>
    );
    return () => {
      setRenderExtraElements?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: PieDataType[] = [
    {
      points: pointsRedeemedCurrentYear?.hotelPoints || 0,
      type: "hotel" as DataType,
    },
    {
      points: pointsRedeemedCurrentYear?.creditCardPoints || 0,
      type: "credit-card" as DataType,
    },
    {
      points: pointsRedeemedCurrentYear?.flightPoints || 0,
      type: "flight" as DataType,
    },
  ]
    .filter((item) => item.points > 0)
    .sort((a, b) => b.points - a.points);

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;

  const biggestPointsRedeedmed: "flight" | "hotel" | null = useMemo(() => {
    const flightPoints =
      pointsRedeemedCurrentYear?.biggestFlightPointsRedeemed?.points || 0;
    const hotelPoints =
      pointsRedeemedCurrentYear?.biggestHotelPointsRedeemed?.points || 0;
    if (hotelPoints === 0 && flightPoints === 0) {
      return null;
    }
    if (flightPoints > hotelPoints) {
      return "flight";
    }
    return "hotel";
  }, [pointsRedeemedCurrentYear]);

  return (
    <div
      className="relative flex flex-1 flex-col items-center overflow-hidden lg:px-16"
      style={{
        background: ellipseColor.white.linearBackground,
      }}
    >
      <div className="absolute bottom-[25%] left-4 -z-10">
        <GondolaMappedDecorations type="Star1" className="lg:scale-150" />
      </div>
      <div className="absolute right-12 top-12 -z-10">
        <GondolaMappedDecorations type="Star2" className="lg:scale-150" />
      </div>
      <div className="absolute bottom-14 right-2 -z-10">
        <GondolaMappedDecorations type="StarBrust" className="lg:scale-150" />
      </div>
      <div className="flex w-full flex-1 flex-col items-center gap-8 pt-10">
        <div className="flex flex-col items-center justify-center gap-1">
          <span className="mx-4 text-center text-4xl font-semibold text-primary">
            <CountUp start={0} end={totalPoints} duration={4} />
          </span>
          <span className="mx-4 text-center text-xl font-semibold text-primary">
            Total Points Redeemed This Year
          </span>
        </div>
        <div
          className={cn(
            "my-auto flex gap-4 px-5",
            isSmallHeight ? "w-[300px]" : "w-[400px]"
          )}
        >
          <PieChart data={data} />
        </div>
      </div>
      {biggestPointsRedeedmed !== null && (
        <motion.span
          initial={{ opacity: 0, filter: "blur(15px)" }}
          animate={{ opacity: 1, filter: "blur(0)" }}
          transition={{
            duration: REVEAL_ANIMATION_DURATION / 1000,
            delay: 2,
          }}
          className={cn(
            "h-[100px] px-5 text-center text-base font-normal text-white/80 lg:text-xl",
            isSmallHeight && "h-fit py-3"
          )}
        >
          {biggestPointsRedeedmed === "flight" ? (
            <>
              Your largest redemption was{" "}
              <span className="font-medium text-primary-gold">
                {formatNumber(
                  pointsRedeemedCurrentYear?.biggestFlightPointsRedeemed
                    ?.points || 0
                )}
              </span>{" "}
              points for a flight from{" "}
              {
                pointsRedeemedCurrentYear?.biggestFlightPointsRedeemed
                  ?.flightDepartureIata
              }{" "}
              to{" "}
              {
                pointsRedeemedCurrentYear?.biggestFlightPointsRedeemed
                  ?.flightArrivalIata
              }
            </>
          ) : (
            <>
              Your largest redemption was{" "}
              <span className="font-medium text-primary-gold">
                {formatNumber(
                  pointsRedeemedCurrentYear?.biggestHotelPointsRedeemed
                    ?.points || 0
                )}
              </span>{" "}
              points for{" "}
              {
                pointsRedeemedCurrentYear?.biggestHotelPointsRedeemed?.hotelStay
                  ?.propertyName
              }
            </>
          )}
        </motion.span>
      )}
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

export default PointsRedeemed;

import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
  size?: number;
};

const GondolaMappedDecorations: React.FC<
  Props & {
    type: "Star1" | "Star2" | "StarBrust";
  }
> = ({ type, ...props }) => {
  switch (type) {
    case "Star1":
      return <Star1 {...props} />;
    case "Star2":
      return <Star2 {...props} />;
    case "StarBrust":
      return <StarBrust {...props} />;
    default:
      return null;
  }
};

const Star1: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path
        fill={color ?? "#16253B"}
        d="m23.662 0 6.39 17.518L47.325 24l-17.271 6.482L23.662 48l-6.39-17.518L0 24l17.271-6.482L23.662 0Z"
      />
    </svg>
  );
};

const Star2: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={41}
      viewBox="0 0 42 41"
      fill="none"
      {...props}
    >
      <path
        fill={color ?? "#16253B"}
        d="m30.195.467-.614 12.418 12.33 1.588-10.092 7.263 6.444 10.63-11.966-3.362-4.296 11.665-4.834-11.452L5.37 33.133l5.941-10.92-10.42-6.78 12.242-2.164L11.943.893l9.323 8.224 8.93-8.65Z"
      />
    </svg>
  );
};

const StarBrust: React.FC<Props> = ({ size = 50 }) => {
  return (
    <img
      src="/assets/mapped/star-burst.png"
      alt="Starburst"
      style={{ height: size, width: size }}
    />
  );
};

export default GondolaMappedDecorations;

import React, { useEffect } from "react";
import { TemplateData, TemplateKeys } from "../tempates";
import { cn, lgBreakPoint, screenInnerWidth } from "@/lib/utils";

type Props = {
  allTemplates: TemplateData[];
  currentTemplate: TemplateKeys;
  isLast: boolean;
  onTemplateChange: (template: TemplateKeys) => void;
  onNext: () => void;
  className?: string;
  autoPlay?: boolean;
};

const perStatusTime = 3; // seconds

const TemplateStatusTab: React.FC<Props> = ({
  allTemplates,
  currentTemplate,
  onNext,
  onTemplateChange,
  isLast,
  className,
  autoPlay,
}) => {
  const currentIdx = allTemplates.findIndex(
    (template) => template.key === currentTemplate
  );

  useEffect(() => {
    if (!autoPlay) return;
    const timeout = setTimeout(
      () => {
        if (isLast) return;
        onNext();
      },
      (perStatusTime + 0.5) * 1000
    );
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate, isLast, autoPlay]);

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  return (
    <div
      className={cn("flex h-6 w-full gap-1", className)}
      id="template-status-tab"
    >
      {allTemplates.map((_, index) => (
        <div
          className="h-1 w-full cursor-pointer rounded-full bg-gondola-mapped-tab"
          key={index}
          onClick={() => onTemplateChange(allTemplates[index].key)}
        >
          <div
            className={cn(
              "h-full rounded-full",
              currentIdx > index && "bg-gondola-mapped-tab-active",
              currentIdx === index &&
                (isLessThanLg
                  ? "animate-gondola-mapped-status"
                  : "bg-gondola-mapped-tab-active")
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default TemplateStatusTab;

import { cn } from "@/lib/utils";
import React from "react";

type Props = {
  head: string | JSX.Element;
  subHead: string;
  items: {
    lable: string;
    color: "white" | "gold";
  }[];
  className?: string;
};

const MapSummaryCard: React.FC<Props> = ({
  head,
  subHead,
  items,
  className,
}) => {
  return (
    <div
      className={cn(
        "relative z-0 flex h-[140px] w-[260px] flex-col items-center justify-evenly overflow-hidden rounded-xl border border-white/10 px-2 shadow-2xl",
        className
      )}
      style={{
        background: "linear-gradient(180deg, #050E19 0%, #0D1F34 100%)",
      }}
    >
      <img
        src="/assets/mapped/card-bg-lines.png"
        alt="lines"
        className="absolute -right-[35%] -top-[10%] scale-[1.2] object-cover"
      />
      {typeof head === "string" ? (
        <span className="text-linear-gradient-default-inverted h-[60px] text-[40px] font-bold">
          {head}
        </span>
      ) : (
        head
      )}
      <span className="text-sm font-semibold uppercase tracking-wider text-[#707d85]">
        {subHead}
      </span>
      <div className="flex w-full justify-evenly">
        {items.map((item, index) => (
          <div key={index} className="flex items-center justify-center gap-2">
            <div
              className={cn(
                "h-4 w-4 rounded-full",
                item.color === "gold" && "bg-primary-gold",
                item.color === "white" && "bg-[#afc6d4]"
              )}
            />
            <span className="text-sm font-semibold text-light-3">
              {item.lable}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapSummaryCard;

"use client";

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import GondolaMapped from "./GondolaMapped";
import {
  GondolaMappedResponse,
  UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest,
} from "@/generated/email_parser.openapi";
import TravelReviewModal from "./TravelReviewModal";
import { useGlobalStore } from "@/app/context/globalStoreProvider";
import { usePostHog } from "posthog-js/react";
import { lgBreakPoint, screenInnerWidth } from "@/lib/utils";

type GondolaMappedContextType = {
  onOpenGondolaMapped: () => void;
  renderExtraElements?: React.ReactNode;
  setRenderExtraElements?: React.Dispatch<
    React.SetStateAction<React.ReactNode>
  >;
  fetchGondolaMappedData?: () => void;
  gondolaMappedData?: GondolaMappedResponse;
  gondolaMappedLoading?: boolean;
  canOpenGondolaMapped: boolean;
  enableTravelReviewModal?: () => void;
  isGondolaMappedFeatureEnabled: boolean;
  isWelcomeActionEnabled: boolean;
  deleteGondolaMappedWelcomeAction?: () => void;
  hasSomeData: boolean;
};

const defaultState = {
  onOpenGondolaMapped: () => {},
  canOpenGondolaMapped: false,
  isGondolaMappedFeatureEnabled: false,
  isWelcomeActionEnabled: false,
  hasSomeData: false,
};

const GondolaMappedContext =
  createContext<GondolaMappedContextType>(defaultState);

export default function GondolaMappedProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [renderExtraElements, setRenderExtraElements] =
    React.useState<React.ReactNode>();
  const [gondolaMappedData, setGondolaMappedData] =
    React.useState<GondolaMappedResponse>();
  const [gondolaMappedLoading, setGondolaMappedLoading] = React.useState(false);
  const [enableTravelReview, setEnableTravelReview] = React.useState(false);
  const [openTravelReviewModal, setOpenTravelReviewModal] =
    React.useState(false);
  const posthog = usePostHog();
  const isGondolaMappedFeatureEnabled =
    posthog.isFeatureEnabled("gondola-mapped") || false;

  const { user, welcomeActions } = useGlobalStore();

  const isWelcomeActionEnabled = Boolean(
    welcomeActions.data?.gondolaMappedWelcomeAction?.id !== undefined
  );

  const isEnabled = Boolean(
    isGondolaMappedFeatureEnabled && isWelcomeActionEnabled
  );

  const fetchGondolaMappedData = useCallback(async () => {
    setGondolaMappedLoading(true);
    const gondolaMappedData = (await fetch("/api/user/gondola_mapped/get").then(
      (resp) => resp.json()
    )) as GondolaMappedResponse;
    setGondolaMappedData?.(gondolaMappedData);
    setGondolaMappedLoading(false);
  }, []);

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  useEffect(() => {
    if (gondolaMappedData && !gondolaMappedLoading && isEnabled) {
      if (isLessThanLg) {
        setOpenTravelReviewModal(true);
      } else {
        setIsOpen(true);
      }
    }
  }, [gondolaMappedLoading, gondolaMappedData, isEnabled, isLessThanLg]);

  useEffect(() => {
    if (isEnabled) fetchGondolaMappedData?.();
  }, [fetchGondolaMappedData, isEnabled]);

  const deleteGondolaMappedWelcomeAction = useCallback(async () => {
    const request: UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest = {
      userId: user?.data.id as string,
      welcomeActionId: welcomeActions.data?.gondolaMappedWelcomeAction
        ?.id as number,
    };
    await fetch("/api/user/welcome_actions/delete", {
      method: "POST",
      body: JSON.stringify(request),
    });
  }, [user?.data.id, welcomeActions.data?.gondolaMappedWelcomeAction?.id]);

  const canOpenGondolaMapped = useMemo(
    () => Boolean(isEnabled && !gondolaMappedLoading && gondolaMappedData),
    [isEnabled, gondolaMappedLoading, gondolaMappedData]
  );

  const hasSomeData = useMemo(
    () => Object.keys(gondolaMappedData || {}).length > 0,
    [gondolaMappedData]
  );

  return (
    <GondolaMappedContext.Provider
      value={{
        onOpenGondolaMapped: () => setIsOpen(true),
        canOpenGondolaMapped,
        renderExtraElements,
        setRenderExtraElements,
        fetchGondolaMappedData,
        gondolaMappedData,
        gondolaMappedLoading,
        enableTravelReviewModal: () => setEnableTravelReview(true),
        isGondolaMappedFeatureEnabled,
        deleteGondolaMappedWelcomeAction,
        isWelcomeActionEnabled,
        hasSomeData,
      }}
    >
      {enableTravelReview && (
        <TravelReviewModal
          open={openTravelReviewModal}
          onOpenChange={(val) => {
            if (val === false) deleteGondolaMappedWelcomeAction();
            setOpenTravelReviewModal(val);
          }}
          gondolaMappedLoading={gondolaMappedLoading}
          onGondolaMapped={() => {
            setOpenTravelReviewModal(false);
            setIsOpen(true);
          }}
        />
      )}
      <GondolaMapped open={isOpen} onOpenChange={setIsOpen} />
      {children}
    </GondolaMappedContext.Provider>
  );
}

export const useGondolaMapped = () => {
  const {
    onOpenGondolaMapped,
    renderExtraElements,
    setRenderExtraElements,
    fetchGondolaMappedData,
    gondolaMappedData,
    gondolaMappedLoading,
    canOpenGondolaMapped,
    enableTravelReviewModal,
    isGondolaMappedFeatureEnabled,
    deleteGondolaMappedWelcomeAction,
    isWelcomeActionEnabled,
    hasSomeData,
  } = useContext(GondolaMappedContext);
  return {
    onOpenGondolaMapped,
    renderExtraElements,
    setRenderExtraElements,
    fetchGondolaMappedData,
    gondolaMappedData,
    gondolaMappedLoading,
    canOpenGondolaMapped,
    enableTravelReviewModal,
    isGondolaMappedFeatureEnabled,
    deleteGondolaMappedWelcomeAction,
    isWelcomeActionEnabled,
    hasSomeData,
  };
};

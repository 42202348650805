import { theme } from "@/themes";
import { createRoot } from "react-dom/client";

type MapPolyline = {
  from: google.maps.LatLngLiteral;
  to: google.maps.LatLngLiteral;
};

export const setCityAdvancedMarker = (
  color: "gold" | "white",
  options: google.maps.marker.AdvancedMarkerElementOptions
) => {
  const dotColor =
    color === "gold" ? theme.extend.colors["primary-gold"] : "#afc6d4";

  const div = document.createElement("div");
  const root = createRoot(div);

  root.render(
    <div className="-mb-2">
      <div
        className="relative h-4 w-4 rounded-full"
        style={{ background: `${dotColor}30` }}
      >
        <div
          className="absolute left-1/2 top-1/2 h-2 w-2 -translate-x-1/2 -translate-y-1/2 transform rounded-full border border-white"
          style={{ background: dotColor }}
        />
      </div>
    </div>
  );

  const cityMarker = new google.maps.marker.AdvancedMarkerElement({
    collisionBehavior: google.maps.CollisionBehavior.REQUIRED,
    content: div,
    ...options,
  });

  return cityMarker;
};

export const setFlightPinAdvancedMarker = (
  options: google.maps.marker.AdvancedMarkerElementOptions
) => {
  const div = document.createElement("div");
  const root = createRoot(div);

  root.render(
    <img src="/assets/FlightMapPin.png" alt="FlightMapPin" className="-mb-2" />
  );

  const flightMarker = new google.maps.marker.AdvancedMarkerElement({
    collisionBehavior: google.maps.CollisionBehavior.REQUIRED,
    content: div,
    ...options,
  });

  return flightMarker;
};

export const getPolyline = ({ from, to }: MapPolyline): google.maps.Polyline =>
  new google.maps.Polyline({
    path: [to, from],
    geodesic: true,
    strokeColor: "#DCA37A",
    strokeOpacity: 0,
    strokeWeight: 2,
    clickable: false,
    icons: [
      {
        icon: {
          path: "M 0,-1 0,1",
          strokeOpacity: 1,
          scale: 4,
        },
        offset: "0",
        repeat: "20px",
      },
    ],
  });

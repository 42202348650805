import React from "react";
import { TemplateComponentProps } from ".";
import { useGondolaMapped } from "../gondolaMapperProvider";
import {
  cn,
  lgBreakPoint,
  screenInnerHeight,
  screenInnerWidth,
  smallHeightBreakPoint,
} from "@/lib/utils";
import _ from "lodash";
import { motion } from "motion/react";
import CountUp from "react-countup";
import BackgroundMap from "@/components/ui/svgComponents/BackgroundMap";

const TRANSITION_DURATION = 0.5;
const COUNT_UP_DURATION = 1.5;

const YearSummary: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { gondolaMappedData } = useGondolaMapped();
  const totalPoints =
    gondolaMappedData?.currentYearEndPoints?.creditCardPoints !== undefined ||
    gondolaMappedData?.currentYearEndPoints?.hotelPoints !== undefined ||
    gondolaMappedData?.currentYearEndPoints?.flightPoints !== undefined
      ? (gondolaMappedData?.currentYearEndPoints?.creditCardPoints || 0) +
        (gondolaMappedData?.currentYearEndPoints?.hotelPoints || 0) +
        (gondolaMappedData?.currentYearEndPoints?.flightPoints || 0)
      : null;

  const totalPointsRedeemed =
    gondolaMappedData?.pointsRedeemedCurrentYear?.creditCardPoints !==
      undefined ||
    gondolaMappedData?.pointsRedeemedCurrentYear?.hotelPoints !== undefined ||
    gondolaMappedData?.pointsRedeemedCurrentYear?.flightPoints !== undefined
      ? (gondolaMappedData?.pointsRedeemedCurrentYear?.creditCardPoints || 0) +
        (gondolaMappedData?.pointsRedeemedCurrentYear?.hotelPoints || 0) +
        (gondolaMappedData?.pointsRedeemedCurrentYear?.flightPoints || 0)
      : null;

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;

  return (
    <div
      className={cn(
        "overflow-hiddens relative z-10 flex flex-1 flex-col items-center justify-center gap-6",
        isSmallHeight ? "pb-5" : "pb-14"
      )}
    >
      <BackgroundMap className="absolute inset-0 -left-20 h-4/5 lg:-left-10" />
      <div className="z-10 flex flex-col items-center justify-end gap-6">
        <span
          className={cn(
            "mx-4 text-center text-xl font-semibold text-primary",
            isSmallHeight ? "mt-5" : "mt-10"
          )}
        >
          What a year you’ve had!
        </span>
      </div>
      <div
        className={cn(
          "z-10 grid w-[90%] flex-1 grid-cols-2 gap-4 gap-y-4 lg:gap-y-8",
          isSmallHeight ? "mt-0" : "mt-2"
        )}
      >
        {gondolaMappedData?.totalCountriesVisited ? (
          <Item
            label="Countries Visited"
            value={
              _.filter(gondolaMappedData?.totalCountriesVisited.items, {
                year: 2024,
              }).length
            }
            delay={0}
          />
        ) : null}
        {gondolaMappedData?.totalCitiesVisited ? (
          <Item
            label="Cities Visited"
            value={
              _.filter(gondolaMappedData?.totalCitiesVisited.items, {
                year: 2024,
              }).length
            }
            delay={TRANSITION_DURATION}
          />
        ) : null}
        {gondolaMappedData?.totalFlightsTaken?.currentYearFlights ? (
          <Item
            label="Flights Taken"
            value={
              gondolaMappedData?.totalFlightsTaken.currentYearFlights.length
            }
            delay={TRANSITION_DURATION * 2}
          />
        ) : null}
        {gondolaMappedData?.hotelStays
          ?.currentYearNumberOfNightsStayedInHotels ? (
          <Item
            label="Nights Stayed"
            value={
              gondolaMappedData.hotelStays
                .currentYearNumberOfNightsStayedInHotels
            }
            delay={TRANSITION_DURATION * 3}
          />
        ) : null}
        {totalPoints ? (
          <Item
            label="Total Points Balance"
            value={totalPoints}
            delay={TRANSITION_DURATION * 4}
          />
        ) : null}
        {totalPointsRedeemed ? (
          <Item
            label="Points Redeemed"
            value={totalPointsRedeemed}
            delay={TRANSITION_DURATION * 5}
          />
        ) : null}
      </div>
      {gondolaMappedData?.travelerType?.flightsPercentile ||
      gondolaMappedData?.travelerType?.hotelStaysPercentile ? (
        <div
          className={cn(
            "z-10 flex w-full flex-col items-center justify-center gap-4",
            isSmallHeight ? "mt-0" : "mt-4"
          )}
        >
          <motion.div
            className={cn(
              "text-center text-primary",
              isSmallHeight ? "text-sm" : "text-base"
            )}
            transition={{
              delay: TRANSITION_DURATION * 5.5,
              duration: TRANSITION_DURATION,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            Compared to Other Gondola Travellers
          </motion.div>
          <div className="grid w-[90%] grid-cols-2 gap-4 gap-y-8">
            {gondolaMappedData?.travelerType?.flightsPercentile ? (
              <Item
                label={<span>in Flights Taken</span>}
                value={
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className={cn(
                        "text-center font-semibold text-primary-gold",
                        isSmallHeight ? "text-[24px]" : "text-4xl"
                      )}
                    >
                      Top{" "}
                      <CountUp
                        start={0}
                        end={
                          100 -
                          gondolaMappedData?.travelerType?.flightsPercentile
                        }
                        duration={COUNT_UP_DURATION}
                        delay={TRANSITION_DURATION * 6 + 0.5}
                      />
                      %
                    </div>
                  </div>
                }
                delay={TRANSITION_DURATION * 6}
              />
            ) : null}
            {gondolaMappedData?.travelerType?.hotelStaysPercentile ? (
              <Item
                label={<span>in Hotel Nights</span>}
                value={
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className={cn(
                        "text-center font-semibold text-primary-gold",
                        isSmallHeight ? "text-[24px]" : "text-4xl"
                      )}
                    >
                      Top{" "}
                      <CountUp
                        start={0}
                        end={
                          100 -
                          gondolaMappedData?.travelerType?.hotelStaysPercentile
                        }
                        duration={COUNT_UP_DURATION}
                        delay={TRANSITION_DURATION * 7 + 0.5}
                      />
                      %
                    </div>
                  </div>
                }
                delay={TRANSITION_DURATION * 7}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

const Item: React.FC<{
  label: string | JSX.Element;
  value: number | JSX.Element;
  delay?: number;
}> = ({ label, value, delay }) => {
  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;

  return (
    <motion.div
      className={cn(
        "flex flex-col items-center justify-center",
        isSmallHeight ? "gap-1" : "gap-2"
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay, duration: TRANSITION_DURATION, ease: "easeIn" }}
    >
      {typeof value === "number" ? (
        <span
          className={cn(
            "font-semibold text-primary-gold",
            isSmallHeight ? "text-[32px]" : "text-4xl"
          )}
        >
          <CountUp
            start={0}
            end={value}
            duration={COUNT_UP_DURATION}
            delay={(delay || 0) + 0.5}
          />
        </span>
      ) : (
        value
      )}
      <span
        className={cn(
          "whitespace-nowrap text-[15px] font-semibold text-white/80",
          isSmallHeight ? "text-[13px]" : "text-[15px]"
        )}
      >
        {label}
      </span>
    </motion.div>
  );
};

export default YearSummary;

import React, { useEffect } from "react";
import { TemplateComponentProps } from ".";
import MapComponent, {
  INITIAL_DELAY,
  TOTAL_FLIGHTS_TAKEN_ANIMATION,
} from "../components/MapComponent";
import MapSummaryCard from "../components/MapSummaryCard";
import Ellipse, { ellipseColor } from "../components/Ellipse";
import { useGondolaMapped } from "../gondolaMapperProvider";
import { formatNumber, lgBreakPoint, screenInnerWidth } from "@/lib/utils";
import GondolaMappedDecorations from "@/components/ui/svgComponents/GondolaMappedDecorations";
import CountUp from "react-countup";
import { motion } from "motion/react";

const REVEAL_ANIMATION_DURATION = 1500;

const FlightsTaken: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { setRenderExtraElements, gondolaMappedData } = useGondolaMapped();

  useEffect(() => {
    setRenderExtraElements?.(
      <div className="absolute -left-28 -top-10 z-0">
        <Ellipse color="purple" />
      </div>
    );
    return () => {
      setRenderExtraElements?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  return (
    <div
      className="relative flex flex-1 flex-col items-center justify-evenly overflow-hidden"
      style={{
        background: ellipseColor.purple.linearBackground,
      }}
    >
      <div className="absolute bottom-[25%] left-4">
        <GondolaMappedDecorations type="Star1" className="lg:scale-150" />
      </div>
      <div className="absolute left-12 top-[15%]">
        <GondolaMappedDecorations type="StarBrust" className="lg:scale-150" />
      </div>
      <div className="absolute right-12 top-[24%]">
        <GondolaMappedDecorations type="Star2" className="lg:scale-150" />
      </div>
      <span className="z-10 mx-4 text-center text-4xl font-semibold text-primary">
        Total Flights
        <br />
        Taken This Year
      </span>
      <div className="relative mb-[110px] flex flex-col items-center justify-center">
        <MapComponent flightsTaken={gondolaMappedData?.totalFlightsTaken} />
        <MapSummaryCard
          className="absolute -bottom-[100px]"
          head={
            <span className="text-linear-gradient-default-inverted text-[40px] font-bold">
              <CountUp
                start={0}
                end={
                  gondolaMappedData?.totalFlightsTaken?.currentYearFlights
                    ?.length || 0
                }
                duration={TOTAL_FLIGHTS_TAKEN_ANIMATION / 1000}
                useEasing={false}
                delay={0.5 + INITIAL_DELAY / 1000}
              />
            </span>
          }
          subHead="Flights"
          items={[
            {
              lable: `Top Airline: ${gondolaMappedData?.totalFlightsTaken?.currentYearTopAirline}`,
              color: "gold",
            },
          ]}
        />
      </div>
      <div className="mx-8 mb-4 h-12 text-center">
        <motion.span
          initial={{ opacity: 0, filter: "blur(15px)" }}
          animate={{ opacity: 1, filter: "blur(0)" }}
          transition={{
            duration: REVEAL_ANIMATION_DURATION / 1000,
            delay: (TOTAL_FLIGHTS_TAKEN_ANIMATION + INITIAL_DELAY + 500) / 1000,
          }}
          className="text-center text-primary/80 lg:text-xl"
        >
          You took{" "}
          <span className="font-medium text-primary-gold">
            {formatNumber(
              gondolaMappedData?.totalFlightsTaken?.currentYearFlights
                ?.length || 0
            )}
          </span>{" "}
          flights this year, adding to{" "}
          <span className="font-medium text-primary-gold">
            {formatNumber(
              gondolaMappedData?.totalFlightsTaken
                ?.lifetimeTotalNumberOfFlights || 0
            )}
          </span>{" "}
          flights all time.
        </motion.span>
      </div>
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

export default FlightsTaken;

import React, { useEffect, useState } from "react";
import MapComponent, {
  INITIAL_DELAY,
  TOTAL_CITY_FILL_ANIMATION,
} from "../components/MapComponent";
import MapSummaryCard from "../components/MapSummaryCard";
import Ellipse, { ellipseColor } from "../components/Ellipse";
import { useGondolaMapped } from "../gondolaMapperProvider";
import { formatNumber, lgBreakPoint, screenInnerWidth } from "@/lib/utils";
import { TemplateComponentProps } from ".";
import GondolaMappedDecorations from "@/components/ui/svgComponents/GondolaMappedDecorations";
import { motion } from "motion/react";

const REVEAL_ANIMATION_DURATION = 1500;

const CityVisited: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { setRenderExtraElements, gondolaMappedData } = useGondolaMapped();
  const [currentlyLiteCity, setCurrentlyLiteCity] = useState<string>();
  const [initialDelayFinished, setInitialDelayFinished] = useState(false);

  useEffect(() => {
    setRenderExtraElements?.(
      <div className="absolute -left-28 -top-10 z-0">
        <Ellipse color="blue" />
      </div>
    );
    return () => {
      setRenderExtraElements?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalCitiesVisited =
    gondolaMappedData?.totalCitiesVisited?.items.length;

  const totalCitiesVisitedThisYear =
    gondolaMappedData?.totalCitiesVisited?.items.filter(
      (item) => item.year === 2024
    ).length;

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  return (
    <div
      className="relative flex flex-1 flex-col items-center justify-evenly overflow-hidden"
      style={{
        background: ellipseColor.blue.linearBackground,
      }}
    >
      <div className="absolute bottom-[25%] left-4">
        <GondolaMappedDecorations type="Star1" className="lg:scale-150" />
      </div>
      <div className="absolute left-12 top-[15%]">
        <GondolaMappedDecorations type="Star2" className="lg:scale-150" />
      </div>
      <div className="absolute right-12 top-[24%]">
        <GondolaMappedDecorations type="StarBrust" className="lg:scale-150" />
      </div>
      <span className="z-10 mx-4 text-center text-4xl font-semibold text-primary">
        Total Cities
        <br />
        Visited This Year
      </span>
      <div className="relative mb-[110px] flex flex-col items-center justify-center">
        <MapComponent
          cityVisited={gondolaMappedData?.totalCitiesVisited?.items}
          setCurrentlyLiteCity={setCurrentlyLiteCity}
          setInitialDelayFinished={setInitialDelayFinished}
        />
        {totalCitiesVisitedThisYear ? (
          <MapSummaryCard
            className="absolute -bottom-[100px]"
            head={
              !initialDelayFinished ? (
                ""
              ) : currentlyLiteCity ? (
                <div className="line-clamp-1 flex h-[60px] items-center justify-center gap-1 whitespace-nowrap text-center text-[22px]">
                  <span className="text-linear-gradient-default-inverted font-bold">
                    {currentlyLiteCity}
                  </span>
                </div>
              ) : (
                totalCitiesVisitedThisYear.toString()
              )
            }
            subHead="Cities Visited"
            items={[
              { lable: "During 2024", color: "gold" },
              { lable: "All time", color: "white" },
            ]}
          />
        ) : null}
      </div>
      <div className="mx-8 mb-4 h-12 text-center">
        <motion.span
          initial={{ opacity: 0, filter: "blur(15px)" }}
          animate={{ opacity: 1, filter: "blur(0)" }}
          transition={{
            duration: REVEAL_ANIMATION_DURATION / 1000,
            delay: (TOTAL_CITY_FILL_ANIMATION + INITIAL_DELAY + 500) / 1000,
          }}
          className="text-center text-primary/80 lg:text-xl"
        >
          You visited{" "}
          <span className="font-medium text-primary-gold">
            {formatNumber(totalCitiesVisitedThisYear || 0)}
          </span>{" "}
          cities this year, out of{" "}
          <span className="font-medium text-primary-gold">
            {formatNumber(totalCitiesVisited || 0)}
          </span>{" "}
          cities all time.
        </motion.span>
      </div>
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

export default CityVisited;

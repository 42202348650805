import React from "react";

type Props = {
  color?: "white" | "blue" | "purple";
};

export const ellipseColor = {
  white: {
    radialBackground:
      "radial-gradient(50% 50% at 50% 50%, #3F526D 0%, rgba(63, 82, 109, 0.00) 100%)",
    linearBackground:
      "linear-gradient(170deg, rgba(63, 82, 109, 0.00) 72.33%, rgba(63, 82, 109, 0.60) 102.95%), transparent",
  },
  blue: {
    radialBackground:
      "radial-gradient(50% 50% at 50% 50%, #2A99BE 0%, rgba(42, 153, 190, 0.00) 100%)",
    linearBackground:
      "linear-gradient(170deg, rgba(42, 153, 190, 0.00) 72.33%, rgba(42, 153, 190, 0.60) 102.95%), transparent",
  },
  purple: {
    radialBackground:
      "radial-gradient(50% 50% at 50% 50%, #4A4285 0%, rgba(74, 66, 133, 0.00) 100%)",
    linearBackground:
      "linear-gradient(170deg, rgba(74, 66, 133, 0.00) 72.33%, rgba(74, 66, 133, 0.60) 102.95%), transparent",
  },
};

const Ellipse: React.FC<Props> = ({ color }) => {
  return (
    <div
      className="h-[200px] w-[275.14px] origin-top-left rotate-[-10.12deg] rounded-full blur-[81.80px]"
      style={{
        borderRadius: "275.138px",
        background: ellipseColor[color || "white"].radialBackground,
        filter: "blur(40.900001525878906px)",
      }}
    />
  );
};

export default Ellipse;

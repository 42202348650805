import React from "react";
import { TemplateComponentProps } from ".";
import FireworkAnimation from "../../firework/Firework";

const Welcome: React.FC<TemplateComponentProps> = () => {
  return (
    <div
      className="relative mt-10 flex flex-1 flex-col items-center justify-center gap-6 lg:gap-8 lg:px-6"
      style={{
        backgroundImage: `url(/assets/mapped/bg-map.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src="/assets/mapped/logo.png" alt="logo" />
      <div className="absolute -right-8 -top-8">
        <FireworkAnimation />
      </div>
      <div className="absolute -bottom-8 -left-8">
        <FireworkAnimation />
      </div>
      <span className="flex flex-col items-center justify-center gap-1 text-center text-5xl leading-[48px] lg:text-[64px] lg:leading-[64px]">
        <span className="font-semibold text-primary-gold">2024</span>
        <span className="font-semibold text-primary-gold">Mapped</span>
      </span>
      <span className="px-5 text-center text-2xl text-primary/60 lg:text-[32px] lg:leading-[32px]">
        Adventure called, and you definitely answered.
      </span>
    </div>
  );
};

export default Welcome;

import React from "react";
import { TemplateComponentProps } from ".";
import GondolaLogo from "@/components/ui/svgComponents/GondolaLogo";

const Share: React.FC<TemplateComponentProps> = () => {
  return (
    <div
      className="overflow-hiddens relative flex flex-1 flex-col items-center justify-evenly gap-6"
      style={{
        backgroundImage: `url(/assets/mapped/bg-map.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col items-center justify-center gap-3">
        <div className="text-center text-4xl font-semibold text-primary-gold">
          Cheers to 2024!
        </div>
        <div className="text-center text-base font-semibold text-primary/80">
          Let’s make 2025 the best year yet
        </div>
      </div>
      <div className="mb-10 flex w-full flex-col items-start gap-4 px-10 lg:px-28">
        <GondolaLogo />
        <div className="text-3xl font-normal leading-[33px] text-primary">
          Celebrate your year
          <br />
          and earn $20!
        </div>
        <span className="leading-snug text-light-3">
          Tag us @gondola_ai when you share your Gondola Mapped to social and
          we&apos;ll send you $20 in Gondola Cash towards a future hotel stay
          ($60 max).
        </span>
      </div>
    </div>
  );
};

export default Share;

import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const GondolaLogoWithText: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={98}
      height={20}
      viewBox="0 0 98 20"
      fill="none"
      {...props}
    >
      <path
        fill={color ?? "#DCA37A"}
        d="m96.092 14.862-.762-2.065h-4.491l-.762 2.065h-1.622L92.17 5.27H94l3.728 9.59h-1.636Zm-4.796-3.313h3.576l-1.788-4.81-1.788 4.81ZM82.558 13.517h4.74v1.345h-6.251V5.27h1.51v8.246ZM73.748 5.146c2.897 0 4.99 2.065 4.99 4.906 0 2.87-2.093 4.934-4.99 4.934-2.883 0-4.976-2.065-4.976-4.934 0-2.841 2.093-4.906 4.976-4.906Zm0 1.386c-1.996 0-3.437 1.469-3.437 3.52s1.441 3.548 3.437 3.548c2.01 0 3.451-1.497 3.451-3.548s-1.441-3.52-3.45-3.52ZM61.707 5.27c2.911 0 4.92 1.969 4.92 4.782 0 2.842-2.009 4.81-4.92 4.81h-3.146V5.27h3.146Zm-.014 8.247c2.01 0 3.396-1.413 3.396-3.479 0-2.037-1.386-3.423-3.396-3.423h-1.621v6.902h1.621ZM54.245 5.27h1.51v9.592H53.83l-4.352-7.596v7.596h-1.51V5.27h1.94l4.338 7.595V5.27ZM40.668 5.146c2.897 0 4.99 2.065 4.99 4.906 0 2.87-2.093 4.934-4.99 4.934-2.883 0-4.976-2.065-4.976-4.934 0-2.841 2.093-4.906 4.976-4.906Zm0 1.386c-1.996 0-3.438 1.469-3.438 3.52s1.442 3.548 3.438 3.548c2.01 0 3.45-1.497 3.45-3.548s-1.44-3.52-3.45-3.52ZM33.532 9.65c0 .111.014.277.014.402 0 2.87-2.037 4.934-4.962 4.934-2.883 0-4.976-2.065-4.976-4.934 0-2.841 2.093-4.906 4.976-4.906 2.426 0 4.366 1.524 4.81 3.686h-1.58c-.402-1.413-1.608-2.37-3.216-2.37-2.01 0-3.451 1.511-3.451 3.59 0 2.093 1.441 3.604 3.451 3.604 1.733 0 3.077-1.123 3.313-2.73h-3.313V9.65h4.934Z"
      />
      <path
        fill={color ?? "#DCA37A"}
        fillRule="evenodd"
        d="M17.007 10.304a8.49 8.49 0 0 1-5.681 8.012c-.815-3.975-1.093-6.741 4.6-12.16a8.449 8.449 0 0 1 1.081 4.148ZM.927 14.099a8.523 8.523 0 0 0 3.798 3.798c.026-1.907 1.567-3.945 3.108-5.486l7.187-7.565a8.542 8.542 0 0 0-1.043-1.043L6.412 10.99C4.87 12.531 2.834 14.072.926 14.1Zm-.42-6.601c3.975.814 6.742 1.092 12.16-4.6a8.49 8.49 0 0 0-12.16 4.6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default GondolaLogoWithText;

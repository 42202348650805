import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
  color1?: string;
  color2?: string;
};

const Share: React.FC<Props> = ({ color, color1, color2, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={color ?? "#DCA37A"}
        d="m12.761 13.306.015.008.01-.014a2.596 2.596 0 0 1 2.089-1.064 2.608 2.608 0 0 1 2.605 2.606 2.608 2.608 0 0 1-2.605 2.605 2.608 2.608 0 0 1-2.605-2.605c0-.268.052-.52.127-.765l.005-.015-.014-.008-4.124-2.111-.015-.008-.01.014a2.599 2.599 0 0 1-2.114 1.094 2.608 2.608 0 0 1-2.605-2.605 2.608 2.608 0 0 1 2.605-2.606c.78 0 1.472.351 1.95.896l.011.012.014-.008 4.343-2.613.014-.009-.006-.015a2.581 2.581 0 0 1-.181-.937 2.608 2.608 0 0 1 2.605-2.605 2.608 2.608 0 0 1 2.605 2.605 2.608 2.608 0 0 1-2.605 2.606 2.589 2.589 0 0 1-1.975-.926l-.01-.012-.015.008-4.33 2.606-.014.008.007.016c.122.301.192.63.192.974 0 .253-.047.494-.116.727l-.004.015.014.008 4.137 2.118Z"
      />
      <path
        fill="url(#a)"
        d="m12.761 13.306.015.008.01-.014a2.596 2.596 0 0 1 2.089-1.064 2.608 2.608 0 0 1 2.605 2.606 2.608 2.608 0 0 1-2.605 2.605 2.608 2.608 0 0 1-2.605-2.605c0-.268.052-.52.127-.765l.005-.015-.014-.008-4.124-2.111-.015-.008-.01.014a2.599 2.599 0 0 1-2.114 1.094 2.608 2.608 0 0 1-2.605-2.605 2.608 2.608 0 0 1 2.605-2.606c.78 0 1.472.351 1.95.896l.011.012.014-.008 4.343-2.613.014-.009-.006-.015a2.581 2.581 0 0 1-.181-.937 2.608 2.608 0 0 1 2.605-2.605 2.608 2.608 0 0 1 2.605 2.605 2.608 2.608 0 0 1-2.605 2.606 2.589 2.589 0 0 1-1.975-.926l-.01-.012-.015.008-4.33 2.606-.014.008.007.016c.122.301.192.63.192.974 0 .253-.047.494-.116.727l-.004.015.014.008 4.137 2.118Z"
      />
      <path
        stroke="#fff"
        strokeWidth={0.039}
        d="m12.761 13.306.015.008.01-.014a2.596 2.596 0 0 1 2.089-1.064 2.608 2.608 0 0 1 2.605 2.606 2.608 2.608 0 0 1-2.605 2.605 2.608 2.608 0 0 1-2.605-2.605c0-.268.052-.52.127-.765l.005-.015-.014-.008-4.124-2.111-.015-.008-.01.014a2.599 2.599 0 0 1-2.114 1.094 2.608 2.608 0 0 1-2.605-2.605 2.608 2.608 0 0 1 2.605-2.606c.78 0 1.472.351 1.95.896l.011.012.014-.008 4.343-2.613.014-.009-.006-.015a2.581 2.581 0 0 1-.181-.937 2.608 2.608 0 0 1 2.605-2.605 2.608 2.608 0 0 1 2.605 2.605 2.608 2.608 0 0 1-2.605 2.606 2.589 2.589 0 0 1-1.975-.926l-.01-.012-.015.008-4.33 2.606-.014.008.007.016c.122.301.192.63.192.974 0 .253-.047.494-.116.727l-.004.015.014.008 4.137 2.118Z"
      />
      <path
        stroke="url(#b)"
        strokeWidth={0.039}
        d="m12.761 13.306.015.008.01-.014a2.596 2.596 0 0 1 2.089-1.064 2.608 2.608 0 0 1 2.605 2.606 2.608 2.608 0 0 1-2.605 2.605 2.608 2.608 0 0 1-2.605-2.605c0-.268.052-.52.127-.765l.005-.015-.014-.008-4.124-2.111-.015-.008-.01.014a2.599 2.599 0 0 1-2.114 1.094 2.608 2.608 0 0 1-2.605-2.605 2.608 2.608 0 0 1 2.605-2.606c.78 0 1.472.351 1.95.896l.011.012.014-.008 4.343-2.613.014-.009-.006-.015a2.581 2.581 0 0 1-.181-.937 2.608 2.608 0 0 1 2.605-2.605 2.608 2.608 0 0 1 2.605 2.605 2.608 2.608 0 0 1-2.605 2.606 2.589 2.589 0 0 1-1.975-.926l-.01-.012-.015.008-4.33 2.606-.014.008.007.016c.122.301.192.63.192.974 0 .253-.047.494-.116.727l-.004.015.014.008 4.137 2.118Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={10.5}
          x2={10.5}
          y1={2.533}
          y2={17.467}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1 ?? "#EBCAB2"} />
          <stop offset={1} stopColor={color2 ?? "#DCA37A"} />
        </linearGradient>
        <linearGradient
          id="b"
          x1={10.5}
          x2={10.5}
          y1={2.533}
          y2={17.467}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1 ?? "#EBCAB2"} />
          <stop offset={1} stopColor={color2 ?? "#DCA37A"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Share;

import Share from "@/components/ui/svgComponents/Share";
import { cn } from "@/lib/utils";
import React from "react";

type Props = {
  onShare: () => void;
  className?: string;
};

const ShareContainer: React.FC<Props> = ({ onShare, className }) => {
  return (
    <div
      className={cn(
        "flex min-h-20 w-full items-center justify-center rounded-b-2xl bg-transparent",
        className
      )}
      id="template-share-container"
    >
      <div
        className="flex cursor-pointer items-center justify-center gap-3 text-primary-gold"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onShare();
        }}
      >
        <div className="flex max-h-8 min-h-8 min-w-8 max-w-8 items-center justify-center rounded-full border border-white/20 lg:max-h-10 lg:min-h-10 lg:min-w-10 lg:max-w-10">
          <Share className="-ml-0.5" />
        </div>{" "}
        <span className="text-sm font-semibold">Share this story</span>
      </div>
    </div>
  );
};

export default ShareContainer;

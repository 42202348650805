import React, { useEffect } from "react";
import { TemplateComponentProps } from ".";
import Ellipse, { ellipseColor } from "../components/Ellipse";
import { useGondolaMapped } from "../gondolaMapperProvider";
import {
  calculateNumberOfNights,
  cn,
  getDateFormat,
  lgBreakPoint,
  screenInnerHeight,
  screenInnerWidth,
  smallHeightBreakPoint,
} from "@/lib/utils";
import { format } from "date-fns";
import { theme } from "@/themes";
import { GondolaMappedHotelStay } from "@/generated/email_parser.openapi";
import { motion } from "motion/react";

const REVEAL_ANIMATION_DURATION = 1500;

const HotelStays: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { setRenderExtraElements, gondolaMappedData } = useGondolaMapped();

  useEffect(() => {
    setRenderExtraElements?.(
      <div className="absolute -left-28 -top-10 z-0">
        <Ellipse color="blue" />
      </div>
    );
    return () => {
      setRenderExtraElements?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;

  return (
    <div
      className="relative flex flex-1 flex-col items-center overflow-hidden"
      style={{
        background: ellipseColor.blue.linearBackground,
      }}
    >
      <div
        className={cn(
          "flex flex-1 flex-col items-center pt-8 lg:gap-2",
          isSmallHeight ? "gap-1 pt-2" : "gap-8"
        )}
      >
        <div className="mx-4 mb-2 text-center font-semibold text-primary">
          <div className="mb-2 text-4xl">
            {
              gondolaMappedData?.hotelStays
                ?.currentYearNumberOfNightsStayedInHotels
            }
          </div>
          <div className="text-xl">Nights Stayed In Hotels This Year</div>
        </div>
        <div className="text-base font-medium text-primary-gold">
          TOP {gondolaMappedData?.hotelStays?.topFourHotelStays.length} STAYS
        </div>
        <div className="grid grid-cols-2 gap-3">
          {gondolaMappedData?.hotelStays?.topFourHotelStays.map(
            (hotel, index) => (
              <HotelCard key={index} hotel={hotel} delay={index * 1500} />
            )
          )}
        </div>
      </div>
      <div className="my-6 flex flex-col items-center justify-center gap-3">
        <motion.span
          initial={{ opacity: 0, filter: "blur(15px)" }}
          animate={{ opacity: 1, filter: "blur(0)" }}
          transition={{
            duration: REVEAL_ANIMATION_DURATION / 1000,
            delay: 5.5,
          }}
          className="px-5 text-center text-base font-normal text-white/80 lg:text-xl"
        >
          You stayed in{" "}
          <span className="font-medium text-primary-gold">
            {gondolaMappedData?.hotelStays?.currentYearTotalNumberOfHotels}
          </span>{" "}
          unique hotels this year.
          <br />
          Your travel game is strong!
        </motion.span>
      </div>
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

const HotelCard: React.FC<{
  hotel?: GondolaMappedHotelStay;
  delay?: number;
}> = ({ hotel, delay }) => {
  const checkIn = hotel?.checkInDatetime
    ? getDateFormat(hotel?.checkInDatetime)
    : null;
  const checkOut = hotel?.checkOutDatetime
    ? getDateFormat(hotel?.checkOutDatetime)
    : null;
  const nights =
    checkIn && checkOut ? calculateNumberOfNights(checkIn, checkOut) : null;

  const isSmallHeight = screenInnerHeight < smallHeightBreakPoint;

  return (
    <motion.div
      className={cn(
        "relative h-[150px] w-[160px] rounded-2xl lg:h-[180px] lg:w-[190px]",
        isSmallHeight ? "h-[130px] w-[150px]" : ""
      )}
      initial={{ opacity: 0, filter: "blur(15px)" }}
      animate={{ opacity: 1, filter: "blur(0)" }}
      transition={{
        duration: REVEAL_ANIMATION_DURATION / 1000,
        delay: (delay || 0) / 1000,
      }}
    >
      <div
        className="absolute left-0 top-0 h-full w-full rounded-2xl"
        style={{
          backgroundImage: `linear-gradient(180deg, transparent 60%, ${theme.extend.colors.card["background-dark"]} 85%), url(${hotel?.heroImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className="absolute bottom-1 flex w-full flex-col items-start justify-center gap-0.5 px-2">
        <span className="line-clamp-1 text-start text-xs font-medium text-primary">
          {hotel?.propertyName}
        </span>
        {checkIn && checkOut && nights && (
          <span className="text-[10px] font-semibold text-[#707d85]">
            {format(getDateFormat(checkIn), "MMM dd")} -{" "}
            {format(getDateFormat(checkOut), " MMM dd")} | {nights} Days
          </span>
        )}
      </div>
    </motion.div>
  );
};

export default HotelStays;

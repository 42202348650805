import React, { useEffect, useState } from "react";
import MapComponent from "../components/MapComponent";
import MapSummaryCard from "../components/MapSummaryCard";
import Ellipse, { ellipseColor } from "../components/Ellipse";
import { useGondolaMapped } from "../gondolaMapperProvider";
import { TemplateComponentProps } from ".";
import { getCountryName, lgBreakPoint, screenInnerWidth } from "@/lib/utils";
import GondolaMappedDecorations from "@/components/ui/svgComponents/GondolaMappedDecorations";
import { GondolaMappedCountry } from "@/generated/email_parser.openapi";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { motion } from "motion/react";

const REVEAL_ANIMATION_DURATION = 1500;

const CountryVisited: React.FC<TemplateComponentProps> = ({ showShare }) => {
  const { setRenderExtraElements, gondolaMappedData } = useGondolaMapped();
  const [currentlyLiteCountry, setCurrentlyLiteCountry] =
    useState<GondolaMappedCountry>();
  const [initialDelayFinished, setInitialDelayFinished] = useState(false);

  useEffect(() => {
    setRenderExtraElements?.(
      <div className="absolute -left-28 -top-10 z-0">
        <Ellipse color="white" />
      </div>
    );
    return () => {
      setRenderExtraElements?.(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalCountriesVisited =
    gondolaMappedData?.totalCountriesVisited?.items.length;

  const totalCountriesVisitedThisYear =
    gondolaMappedData?.totalCountriesVisited?.items.filter(
      (item) => item.year === 2024
    ).length;

  const isLessThanLg = screenInnerWidth < lgBreakPoint;

  return (
    <div
      className="relative flex flex-1 flex-col items-center justify-evenly overflow-hidden"
      style={{
        background: ellipseColor.white.linearBackground,
      }}
    >
      <div className="absolute bottom-[25%] left-4">
        <GondolaMappedDecorations type="Star1" className="lg:scale-150" />
      </div>
      <div className="absolute right-12 top-2">
        <GondolaMappedDecorations type="Star2" className="lg:scale-150" />
      </div>
      <div className="absolute left-12 top-[24%]">
        <GondolaMappedDecorations type="StarBrust" className="lg:scale-150" />
      </div>
      <span className="z-10 mx-4 text-center text-4xl font-semibold text-primary">
        Total Countries
        <br />
        Visited This Year
      </span>
      <div className="relative mb-[110px] flex flex-col items-center justify-center">
        <MapComponent
          countryVisited={gondolaMappedData?.totalCountriesVisited?.items}
          setCurrentlyLiteCountry={setCurrentlyLiteCountry}
          setInitialDelayFinished={setInitialDelayFinished}
        />
        {totalCountriesVisitedThisYear ? (
          <MapSummaryCard
            className="absolute -bottom-[100px]"
            head={
              !initialDelayFinished ? (
                ""
              ) : currentlyLiteCountry ? (
                <div className="line-clamp-1 flex h-[60px] items-center justify-center gap-1 whitespace-nowrap text-center text-[22px]">
                  {getUnicodeFlagIcon(currentlyLiteCountry.countryCode)}{" "}
                  <span className="text-linear-gradient-default-inverted font-bold">
                    {getCountryName(currentlyLiteCountry.countryCode)}
                  </span>
                </div>
              ) : (
                totalCountriesVisitedThisYear.toString()
              )
            }
            subHead={`countr${(totalCountriesVisited || 0) > 1 ? "ies" : "y"} Visited`}
            items={[
              { lable: "During 2024", color: "gold" },
              { lable: "All time", color: "white" },
            ]}
          />
        ) : null}
      </div>
      <div className="mx-8 mb-4 h-12 text-center">
        {currentlyLiteCountry === undefined && initialDelayFinished ? (
          <motion.span
            initial={{ opacity: 0, filter: "blur(15px)" }}
            animate={{ opacity: 1, filter: "blur(0)" }}
            transition={{
              duration: REVEAL_ANIMATION_DURATION / 1000,
              delay: 0.5,
            }}
            className="text-center text-primary/80 lg:text-xl"
          >
            You visited{" "}
            <span className="font-medium text-primary-gold">
              {totalCountriesVisitedThisYear}
            </span>{" "}
            countries this year, out of{" "}
            <span className="font-medium text-primary-gold">
              {totalCountriesVisited}
            </span>{" "}
            countries all time.
          </motion.span>
        ) : null}
      </div>
      {!isLessThanLg && showShare && <div className="h-20" />}
    </div>
  );
};

export default CountryVisited;

import { theme } from "@/themes";
import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const GondolaLogo: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={63}
      fill="none"
      viewBox="0 0 64 63"
      {...props}
    >
      <path
        fill={color ?? theme.extend.colors["primary-gold"]}
        fillRule="evenodd"
        d="M63.05 32.461c0 13.942-8.932 25.798-21.386 30.157-3.064-14.96-4.112-25.372 17.317-45.77a31.797 31.797 0 0 1 4.07 15.613ZM2.53 46.748a32.08 32.08 0 0 0 14.291 14.29c.103-7.178 5.9-14.844 11.699-20.642L55.57 11.92a32.137 32.137 0 0 0-3.922-3.922L23.174 35.05C17.376 40.848 9.71 46.645 2.531 46.748ZM.95 21.905c14.96 3.064 25.374 4.113 45.772-17.317A31.798 31.798 0 0 0 31.108.518C17.166.518 5.31 9.451.951 21.905Z"
        clipRule="evenodd"
        style={{
          fill: color ?? theme.extend.colors["primary-gold"],
          fillOpacity: 1,
        }}
      />
    </svg>
  );
};

export default GondolaLogo;

import CityVisited from "./CityVisited";
import CountryVisited from "./CountryVisited";
import FlightsTaken from "./FlightsTaken";
import HotelStays from "./HotelStays";
import PointsRedeemed from "./PointsRedeemed";
import PointsTotal from "./PointsTotal";
import Welcome from "./Welcome";
import YearSummary from "./YearSummary";
import TopTraveller from "./TopTraveller";
import Share from "./Share";

export enum TemplateKeys {
  WELCOME = "welcome",
  COUNTRY_VISITED = "countryVisited",
  CITY_VISITED = "cityVisited",
  FLIGHTS_TAKEN = "flightsTaken",
  HOTEL_STAYS = "hotelStays",
  POINTS_TOTAL = "pointsTotal",
  POINTS_REDEEMED = "pointsRedeemed",
  YEAR_SUMMARY = "yearSummary",
  TOP_TRAVELER = "topTraveler",
  SHARE = "share",
}

export type TemplateComponentProps = {
  showShare?: boolean;
};

export type TemplateData = {
  key: TemplateKeys;
  component: React.FC<TemplateComponentProps>;
  showShare?: boolean;
};

export const templates: TemplateData[] = [
  {
    key: TemplateKeys.WELCOME,
    component: (props) => <Welcome {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.COUNTRY_VISITED,
    component: (props) => <CountryVisited {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.CITY_VISITED,
    component: (props) => <CityVisited {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.FLIGHTS_TAKEN,
    component: (props) => <FlightsTaken {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.HOTEL_STAYS,
    component: (props) => <HotelStays {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.POINTS_TOTAL,
    component: (props) => <PointsTotal {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.POINTS_REDEEMED,
    component: (props) => <PointsRedeemed {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.TOP_TRAVELER,
    component: (props) => <TopTraveller {...props} />,
    showShare: false,
  },
  {
    key: TemplateKeys.YEAR_SUMMARY,
    component: (props) => <YearSummary {...props} />,
    showShare: true,
  },
  {
    key: TemplateKeys.SHARE,
    component: (props) => <Share {...props} />,
    showShare: false,
  },
];

type Props = {
  currentTemplate: TemplateKeys;
};

const Template: React.FC<Props> = ({ currentTemplate }) => {
  const TemplateComponent = templates.find(
    (template) => template.key === currentTemplate
  )?.component;

  return TemplateComponent ? (
    <TemplateComponent
      showShare={
        templates.find((template) => template.key === currentTemplate)
          ?.showShare
      }
    />
  ) : null;
};

export default Template;
